<div class="container py-3">
  <div class="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
    <a [routerLink]="['/']" class="d-flex align-items-center text-dark text-decoration-none brand-logo">
      <h1>Metall Gehalt</h1>
      <p>Dein online Gehaltsrechner</p>
    </a>

    <nav class="d-inline-flex mt-2 mt-md-0 ms-md-auto d-none d-md-block">
      <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/']"> Rechner </a>
      <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/blog/tarifrunden']"> Tarifrunden </a>
      <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/blog']"> Blog </a>
      <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/impressum']"> Impressum </a>
      <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/datenschutz']"> Datenschutz </a>
    </nav>
  </div>
  <router-outlet></router-outlet>
  <hr />
  <div class="row my-3">
    <div class="col-md d-flex flex-column align-items-center">
      <p>
        Bei Abschluss einer
        <a rel="sponsored" href="https://www.awin1.com/awclick.php?gid=372764&mid=15971&awinaffid=1041239&linkid=2520052&clickref=">Zahnzusatzversicherung</a> mit dem Code
        <strong>Hello{{ currentYear }}</strong> einen Amazon Gutschein in höhe von bis zu 30€ bekommen.
      </p>
      <div class="d-md-block d-none">
        <!-- START ADVERTISER: ottonova DE from awin.com -->

        <a rel="sponsored" href="https://www.awin1.com/cread.php?s=2568206&v=15971&q=358850&r=1041239" target="_blank">
          <img class="ad" src="https://www.awin1.com/cshow.php?s=2568206&v=15971&q=358850&r=1041239" border="0" />
        </a>

        <!-- END ADVERTISER: ottonova DE from awin.com -->
      </div>

      <div class="d-block d-md-none">
        <!-- START ADVERTISER: ottonova DE from awin.com -->

        <a rel="sponsored" href="https://www.awin1.com/cread.php?s=2568204&v=15971&q=358850&r=1041239" target="_blank">
          <img class="ad" src="https://www.awin1.com/cshow.php?s=2568204&v=15971&q=358850&r=1041239" border="0" />
        </a>

        <!-- END ADVERTISER: ottonova DE from awin.com -->
      </div>
    </div>
  </div>

  <metall-rechner-google-adsense dataAdClient="ca-pub-2750538732947634" dataAdSlot="6523777521" dataAdFormat="fluid"></metall-rechner-google-adsense>

  <footer class="pt-4 my-md-1 pt-md-5 border-top">
    <div class="row">
      <div class="col-xs-12 col-sm-4">
        <h2>Infos</h2>
        <ul class="list-unstyled text-small">
          <li class="mb-1">
            <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/blog/tarifrunden']"> Tarifrunden </a>
          </li>
          <li class="mb-1">
            <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/blog']"> Blog </a>
          </li>
          <li class="mb-1">
            <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/impressum']">Impressum</a>
          </li>
          <li class="mb-1">
            <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/datenschutz']">Datenschutz</a>
          </li>
        </ul>
      </div>
      <div class="col-xs-12 col-sm-4">
        <h2>Unterstützen</h2>
        <ul class="list-unstyled text-small">
          <li class="mb-1">
            <a class="me-3 py-2 text-dark text-decoration-none" target="_blank" href="https://www.amazon.de/visabounty?tag=metallgehalt-21">
              Kostenlose Amazon Visa Karte (Affiliate)
            </a>
          </li>
          <li class="mb-1">
            <a class="me-3 py-2 text-dark text-decoration-none" target="_blank" href="https://www.buymeacoffee.com/maxcoffee"> Buy me a coffee </a>
          </li>
          <li class="mb-1">
            <a class="me-3 py-2 text-dark text-decoration-none" target="_blank" href="https://www.financeads.net/tc.php?t=59466C377272598T"> Finanzguru (Affiliate) </a>
          </li>
        </ul>
      </div>
      <div class="col-xs-12 col-sm-4">
        <h2>Unterstützen</h2>
        <ul class="list-unstyled text-small">
          <li class="mb-1">
            <a
              class="me-3 py-2 text-dark text-decoration-none"
              target="_blank"
              href="https://traderepublicbank.sjv.io/c/4226892/2396027/23226?productName=BROKERAGE&countryCode=FI"
            >
              2,5% Zinsen bei Traderepublic (Affiliate)
            </a>
          </li>
          <li class="mb-1">
            <a class="me-3 py-2 text-dark text-decoration-none" target="_blank" href="https://www.financeads.net/tc.php?t=59466C3258104056T"> C24 Girokonto (Affiliate) </a>
          </li>
          <li class="mb-1">
            <a class="me-3 py-2 text-dark text-decoration-none" target="_blank" href="https://www.financeads.net/tc.php?t=59466C332661946T"> Zasta Steuererklärung (Affiliate) </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</div>
